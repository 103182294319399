<template>
    <div id="about">
        <div class="info">
            <p>美好在百度百科中指美丽的东西让人身心舒畅，更好地生活，快乐地生活。</p>
            <p>每当遇到一首美好的歌、美好的电影、美好的人与事，总不禁想记录与分享美好。所以阑珊发起者决定创建这样一个社区供朋友们朋友们去发现分享美好。</p>
            <p>阑珊，一个基于朋友间的社区，旨在为志趣相投的朋友们营造一个美好的社区。</p>
            <p>阑珊，给生活加点意思</p>
        </div>
        <div class="time">
            <div class="time_title"><span>阑珊发展历程</span></div>
            <div class="time_list">
                <Timeline>
                    <TimelineItem color="blue">2019-07-22 &nbsp;&nbsp;&nbsp;&nbsp; 公安服务平台审核通过</TimelineItem>
                    <TimelineItem color="blue">2019-07-03 &nbsp;&nbsp;&nbsp;&nbsp; 网站社区模块上线</TimelineItem>
                    <TimelineItem color="blue">2019-07-02 &nbsp;&nbsp;&nbsp;&nbsp; 网站备案通过，网站上线</TimelineItem>
                </Timeline>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "About"
    }
</script>

<style scoped>
    #about {
        width: 675px;
        margin: 0 auto;
        /*width: 100%;*/
        font-size: 14px;
        text-align: left;
        min-height: 550px;
    }

    .info{
        width: 100%;
        white-space: pre-wrap;
        overflow: hidden;
        margin-top: 30px;
        line-height: 1.8;
        color: #4c4c4c
    }
    .info p {
        margin-top: 20px;
    }
    .time{
        margin-top: 40px;
    }
    .time_list {
        margin-top: 20px;
    }
</style>